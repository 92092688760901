import React from "react"
import Layout from "./Layout"
import * as styles from "./StaticLayout.module.css"

const StaticLayout = ({ children }) => (
  <Layout>
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  </Layout>
)

export default StaticLayout
