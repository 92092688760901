import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/gcpvn/gcp.vn/src/components/static-layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Liên Hệ" mdxType="SEO" />
    <h1>{`LIÊN HỆ`}</h1>
    <h3>{`Vui lòng liên hệ Hotline hoặc Facebook GCP để được tư vấn hoặc hỗ trợ nhanh nhất`}</h3>
    <p>{`Hotline CSKH: `}<strong><a href="tel:02477777868">{`024 777 77 868`}</a></strong></p>
    <p>{`Hotline HTKT: `}<strong><a href="tel:02877788088">{`028 777 88 088`}</a></strong></p>
    <hr />
    <p>{`VP Hà Nội: `}<strong>{`Số 145 đường Khương Trung, Phường Khương Trung, Quận Thanh Xuân, Hà Nội`}</strong></p>
    <p>{`VP Hồ Chí Minh: `}<strong>{`155 Bến Vân Đồn, Phường 6, Quận 4, Hồ Chí Minh`}</strong></p>
    <hr />
    <p>{`Facebook: `}<strong><a href="https://www.facebook.com/vn.gcp" target="_blank" rel="noopener noreferrer">{`GCP - Giải Pháp Vận Hành Phòng Net Toàn Diện`}</a></strong></p>
    <p>{`Email: `}<strong><a parentName="p" {...{
          "href": "mailto:contact@gcp.vn"
        }}>{`contact@gcp.vn`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      